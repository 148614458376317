import L from 'leaflet';
import memoize from 'memoizee';
import { TIS_B_CLA_PARAMETERS, UAT_CLA_PARAMETERS } from './globalConstants';

/**
 *
 * @param {number[]} latLng1
 * @param {number[]} latLng2
 * @param {number} accuracy
 * @return {boolean} are latLngs approximately equal
 */
export function compareLatLngApproximately(latLng1, latLng2, accuracy) {
  return (
    latLng1[0].toFixed(accuracy) === latLng2[0].toFixed(accuracy) &&
    latLng1[1].toFixed(accuracy) === latLng2[1].toFixed(accuracy)
  );
}

/**
 * Calculates center of the bounds
 * @param {number[]|null} bounds
 * @return {number[]} tuple [lat, lng]
 */
export function getCenterOfBounds(bounds) {
  const { lat, lng } = L.latLngBounds(bounds).getCenter();

  return [lat, lng];
}

/**
 *
 * @param {{center: number[], zoom: number}} viewport ReactLeaflet viewport
 * @param {number[]|null} bounds
 * @return {boolean} isViewport approximately centered to bounds
 */
export function isCenterOfBounds(viewport, bounds) {
  if (!bounds) return false;
  const { center, zoom } = viewport;
  const accuracy = zoom < 10 ? 1 : 4;

  return compareLatLngApproximately(center, getCenterOfBounds(bounds), accuracy);
}

/**
 * Convert zoom diameter in NM to degrees
 * @param {number} diameter in NM
 * @param {number} lat airport center lat
 */
const zoomDiameterToLatLonShift = (diameter, lat) => {
  const zoomDiameterInKm = diameter * 1.852;
  const zoomRadius = zoomDiameterInKm / 2;
  const coefficientKmToDegree = 1 / 111.32;
  // pi / 180 = 0.018
  return {
    latDegrees: coefficientKmToDegree * zoomRadius,
    lonDegrees: (coefficientKmToDegree / Math.cos(lat * 0.018)) * zoomRadius,
  };
};

/**
 * Generate bounds based on diameter and center of airport
 * @param {number} diameter in NM
 * @param {number} lat airport center lat
 * @param {number} lon airport center lon
 */
export const zoomDiameterToBounds = (diameter, lat, lon) => {
  const { latDegrees, lonDegrees } = zoomDiameterToLatLonShift(diameter, lat);

  return [
    [lat - latDegrees, lon + lonDegrees],
    [lat - latDegrees, lon - lonDegrees],
    [lat + latDegrees, lon + lonDegrees],
    [lat + latDegrees, lon - lonDegrees],
  ];
};

/**
 * Generate bounds based on diameter and center of airport
 * @param {number} diameter in NM
 * @param {number} lat airport center lat
 * @param {number} lon airport center lon
 */
export const zoomDiameterToMaxMinValues = (diameter, lat, lon) => {
  const { latDegrees, lonDegrees } = zoomDiameterToLatLonShift(diameter, lat);

  return {
    maxLat: lat + latDegrees,
    maxLon: lon + lonDegrees,
    minLat: lat - latDegrees,
    minLon: lon - lonDegrees,
  };
};

export const mapDefaultViewport = {
  center: [28, -81],
  zoom: 11,
};
/**
 * Convert degrees to radians
 * @param {number} degrees
 * @returns {number} radians
 */
export function degreesToRadian(degrees) {
  return degrees * (Math.PI / 180);
}

/**
 * Calculate scale factor to preserve specified width at any scale
 * @param {number} scale - the pixiContainer scale
 * @param {number} textureWidth - initial width of the texture
 * @param {number} wantedWidth - width which you want to display
 * @returns {number} scale factor
 */
function calculateScale(scale, textureWidth, wantedWidth = 20) {
  const actualWidth = textureWidth * scale;
  return wantedWidth / actualWidth;
}
const calculateScaleMemo = memoize(calculateScale);
export { calculateScaleMemo as calculateScale };

/**
 * Calculate marker size according to map zoom
 * @param {number} zoom Map zoom
 * @param {number} baseSize Base size visible to user
 * @return {number} scaled size
 */
function calculateMarkerSize(zoom, baseSize) {
  return zoom < 10 ? zoom * (baseSize / 10) : (zoom / 10) * baseSize;
}
const calculateMarkerSizeMemo = memoize(calculateMarkerSize);
export { calculateMarkerSizeMemo as calculateMarkerSize };

/**
 *
 * @param {object} aircraftInfo which we're getting from api
 * You can check propTypes here
 * src/components/LeafletMap/components/AircraftOverlay/index.jsx
 */
export function getPrettyInfoTags(aircraftInfo) {
  const { reg, spd, alt, vrt, fli, typ, squ, gda } = aircraftInfo;
  const EMPTY = '--';

  const prettifyAlt = (altitude) => {
    if (altitude === null) return EMPTY;
    const ALT_LENGTH = 3;
    const val = String(Math.round(altitude / 100));

    if (val.length < ALT_LENGTH) {
      const padCount = ALT_LENGTH - val.length;
      return val.padStart(padCount, '0');
    }

    return val;
  };

  const prettyReg = reg || EMPTY;
  const prettySpd = spd === null ? EMPTY : Math.round(spd / 10);
  //                check if grounded
  const prettyAlt = /G/i.test(gda) ? 'GND' : prettifyAlt(alt);
  const prettyVrt = vrt === null ? EMPTY : Math.round(vrt / 100);
  const prettyFli = fli || EMPTY;
  const prettyTyp = typ ? typ.slice(0, 6) : EMPTY;
  const prettySqu = `SQ ${squ || EMPTY}`;

  return {
    reg: prettyReg,
    spd: prettySpd,
    alt: prettyAlt,
    vrt: prettyVrt,
    fli: prettyFli,
    typ: prettyTyp,
    squ: prettySqu,
  };
}

/**
 * Returns bounds in plain object format (serializable)
 * @param {LatLngBounds} bounds leaflet bounds
 */
export function getPlainBounds(bounds) {
  return {
    vpn: +bounds.getNorth().toFixed(6),
    vps: +bounds.getSouth().toFixed(6),
    vpw: +bounds.getWest().toFixed(6),
    vpe: +bounds.getEast().toFixed(6),
  };
}

/**
 * Returns clamped value
 * @param {number} min
 * @param {number} max
 * @param {number} value
 */
export function clampValue(min, max, value) {
  return Math.min(Math.max(value, min), max);
}

export const TINTS = {
  RED: 0xea1515, // --red-500
  YELLOW: 0xfdc900, // --yellow-500
  ORANGE: 0xff8500, // --orange-500
  BLUE: 0x47baec, // --blue-500
  GREEN: 0xacc717, // --green-500
  GRAY: 0xa79e99, // --warm-grey-500
  BRIGHT_GRAY: 0xe2e2e2, // --grey-100
  PINK: 0xff00aa, // --pink-500
};

export const DEFAULT_TINT = TINTS.YELLOW;
export const EMERGENCY_TINT = TINTS.RED;
export const GROUND_TINT = TINTS.GRAY;
export const EDITING_TINT = TINTS.PINK;

export const faaCategoryToTint = {
  'Part 135': TINTS.ORANGE,
  'Part 91': TINTS.YELLOW,
  'Part 121': TINTS.BLUE,
  GOV: TINTS.GREEN,
  GND: TINTS.GRAY,
  MED: TINTS.BRIGHT_GRAY,
};

export const iconTypeToTextureName = {
  UKN: 'vt_icn_generic',
  UAV: 'vt_icn_UAV',
  BLIMP: 'vt_icn_airship',
  DIR: 'vt_icn_arrow',
  BEACON: 'vt_icn_beacon',
  BUZ: 'vt_icn_businessjet',
  MIL: 'vt_icn_fighter',
  MILH: 'vt_icn_helicopter_military',
  GLD: 'vt_icn_glider',
  GNDEMC: 'vt_icn_ground_emergency',
  GNDA: 'vt_icn_ground_service',
  LSA: 'vt_icn_hang_glider',
  HEL: 'vt_icn_helicopter',
  JET2: 'vt_icn_jet2',
  JETNB: 'vt_icn_jet2',
  JET4: 'vt_icn_jet4',
  JETWB: 'vt_icn_jet4',
  MEL: 'vt_icn_multi_engine_land',
  MET: 'vt_icn_multi_engine_land',
  BLN: 'vt_icn_parachute',
  SEL: 'vt_icn_single_engine_land',
  SET: 'vt_icn_single_engine_land',
  STAR: 'vt_icn_star',
  UFO: 'vt_icn_ufo',
};

export const sourceNames = {
  A0: 'ADS-B',
  A8: 'UAT',
  A4: 'UAT via ADS-B',
  A10: 'TIS-B',
  F14: 'FLARM',
  A9: 'ADS-B Temp.',
  A12: 'GND Vehicle',
  A2: 'TIS-B Anonym.',
  M0: 'MLAT',
};

export const sourceCodes = {
  0: 'MLAT',
  1: '1090ES',
  2: '1090ES ADS-R',
  3: '1090ES TIS-B',
  6: 'UAT',
  7: 'UAT TIS-B/ADS-R',
  8: 'UAT ADS-R',
};

/**
 * Return boolean depending on enabled sources and aircraft source
 * @param {string} src aircraft sourcce
 * @param {number} cla aircraft cla number
 * @param {Object} sources object with sources flags
 * @returns {boolean}
 */
export const isSourceVisible = (src, cla, sources) => {
  const aircraftSource = src?.toLowerCase?.();

  let isVisibleBySources = true;

  switch (cla) {
    // NOTE! Special case for TIS-B, according to JV docs TIS-B aircraft have special cla parameter (1,2,3,10,11)
    case TIS_B_CLA_PARAMETERS.FIRST:
    case TIS_B_CLA_PARAMETERS.SECOND:
    case TIS_B_CLA_PARAMETERS.THIRD:
    case TIS_B_CLA_PARAMETERS.TENTH:
    case TIS_B_CLA_PARAMETERS.ELEVENTH:
      const isTISBSourceActive = sources.t === true;
      isVisibleBySources = isTISBSourceActive;
      break;
    // NOTE! Special case for UAT, according to JV docs UAT aircraft have special cla parameter (4,8)
    case UAT_CLA_PARAMETERS.FOURTH:
    case UAT_CLA_PARAMETERS.EIGHTH:
    case UAT_CLA_PARAMETERS.TWELVE:
      const isUATSourceActive = sources.u === true;
      isVisibleBySources = isUATSourceActive;
      break;
    default:
      isVisibleBySources = Boolean(sources[aircraftSource]);
  }

  return isVisibleBySources;
};

/**
 * Calculates the center coordinates based on an array of coordinates.
 *
 * @param {Array<Array<number>>} coords - An array of coordinates where each element is a pair [latitude, longitude].
 * @returns {Array<number>} - The center coordinates as [latitude, longitude].
 *
 * @example
 * const coordinates = [[37.7749, -122.4194], [34.0522, -118.2437], [40.7128, -74.0060]];
 * const center = calculateCenter(coordinates);
 * // Returns [37.179966666666664, -104.8897], the center of the given coordinates.
 */
export function calculateCenter(coords) {
  var sumLat = 0;
  var sumLng = 0;

  for (var i = 0; i < coords.length; i++) {
    sumLat += coords[i][0];
    sumLng += coords[i][1];
  }

  var centerLat = sumLat / coords.length;
  var centerLng = sumLng / coords.length;

  return [centerLat, centerLng];
}
