import React, { useEffect, useRef, useState } from 'react';
import './FlightHistory.css';
import { toast } from 'react-toastify';
import 'rsuite/DatePicker/styles/index.css';
import MenuButton from '../SideNavigation/components/MenuButton';
import { FlightHistoryData } from './FlightHistoryData';
import { FlightHistoryFilter } from './FlightHistoryFilter';
import { FlightHistoryAddress } from './FlightHistoryAddress';
import { FlightHistoryExport } from './FlightHistoryExport';
import api from 'api/api';
import * as turf from '@turf/turf';
import { useHistory } from 'react-router-dom';
import { LayersUtils } from 'utils/layers.utils';
import { useSelector } from 'react-redux';
import { fromIsoWithTimezone, getAirportDateFormatter, getAirportTimeFormatter } from 'utils/dateTimeHelpers';
import { sourceCodes, sourceNames } from 'utils/mapUtils';
import { DateTime } from 'luxon';

const targetProgress = 80;

const FlightHistorySettings = ({
  centerPoint,
  setLocationFromSearch,
  onHistoryResponse,
  onCenterTypeChange,
  onDataChange,
  centerType,
  map,
}) => {
  const history = useHistory();
  const pointsData = useRef();

  const { airport, timezone } = useSelector((state) => state.auth);

  const [data, setData] = useState({
    radius: 30,
    center: [0, 0],
    altitude: 1000,
    upperCeiling: 0,
    lowerCeiling: 20000,
  });

  const [activities, setActivities] = useState([]);

  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const handleDataChange = (payload) => {
    setData({ ...data, ...payload });
  };

  const onCenterChange = (center) => {
    if (!center) {
      LayersUtils.addRadiusPoint(map, {
        center: 'clear',
        color: '#24c1ff',
        radius: data.radius,
        sourceName: 'marker-radius',
      });

      window.positionMarker.remove();

      return;
    }

    const [lat, lng] = Object.values(center);

    setData({
      ...data,
      center: [lng, lat],
    });

    setLocationFromSearch([lng, lat]);
  };

  const loadHistoryData = async () => {
    try {
      setLoading(true);

      const from = fromIsoWithTimezone(data.from, timezone);
      const to = fromIsoWithTimezone(data.to, timezone);

      const historyData = await api.getHistoryData({
        ...data,
        from,
        to,
        radius: data.radius * 1852,
        center: data.center,
      });

      const infoArray = Object.entries(historyData)
        .filter(([, points]) => points.length > 1)
        .map(([aircraftname, points]) => {
          const pointsArray = points.map(({ altitude, latitude, longitude }) => {
            // 1. If we have GDA === ground return 0
            // 2. If we do not have GDA
            return [longitude, latitude, altitude < 0 ? 0 : altitude];
          });

          const { callsign } = historyData[aircraftname][0];

          return turf.lineString(pointsArray, { aircraftname, callsign }, { id: aircraftname });
        });

      const pointsCollection = Object.entries(historyData)
        .filter(([, points]) => points.length > 1)
        .flatMap(([key, points]) => {
          const pointsArray = points.map(({ altitude, latitude, longitude, ...info }) => {
            const date = new Date(info.received_at);
            return turf.point([longitude, latitude, altitude < 0 ? 0 : altitude], {
              ...info,
              transponder_addr: (+key).toString(16).toUpperCase(),
              altitude,
              received_at: `${getAirportDateFormatter(timezone).format(date)}, ${getAirportTimeFormatter(
                timezone,
                true
              ).format(date)}`,
              source: sourceCodes[info.src_type],
            });
          });

          return pointsArray;
        });

      pointsData.current = turf.featureCollection(pointsCollection);

      const historyCollection = turf.featureCollection(infoArray);

      onHistoryResponse(historyCollection);
      setProgress(100);
      setLoading(false);

      if (!Object.keys(historyData).length) {
        toast.warn('No data received from the server. Please check all fields.');
      } else {
        toast.success('Data loaded successfully.');
      }
      setTimeout(() => {
        setProgress(0);
      }, 2000);
    } catch (error) {
      console.error(error);

      setProgress(0);
      setLoading(false);
      toast.error('Something went wrong while loading flight data.');
    }
  };

  const getActivitiesList = async () => {
    const data = await api.getActivitiesByAirport({
      airport: airport.id,
    });

    const activitiesList = data.map(({ id, name }) => ({ value: id, name }));

    setActivities(activitiesList);
  };

  const closeModal = () => history.push('/dashboard');

  useEffect(() => {
    if (!isLoading) return;
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < targetProgress) {
          const increment = Math.random() * (5 - 0.1) + 0.1;
          return Math.min(prev + increment, targetProgress);
        }
        clearInterval(interval);
        return prev;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    onDataChange(data);
  }, [data]);

  useEffect(() => {
    handleDataChange({ center: centerPoint });
  }, [centerPoint]);

  useEffect(() => {
    getActivitiesList();
  }, []);

  return (
    <div className="flight-history">
      <div className="flight-history-header">
        <div className="flight-history-header__title">Noise Abatement</div>
        <MenuButton className="flight-history-header__close" isCloseButton onClick={closeModal} />
      </div>
      <div className="flight-history-loader">
        <div
          className="flight-history-loader__bar"
          style={{
            width: `${progress}%`,
            opacity: progress ? 1 : 0,
          }}
        ></div>
      </div>
      <div className="flight-history-body">
        <FlightHistoryAddress setLocationFromSearch={onCenterChange} />
        <FlightHistoryData
          onChange={handleDataChange}
          centerType={centerType}
          onCenterTypeChange={onCenterTypeChange}
        />
        <FlightHistoryFilter
          isLoading={isLoading}
          activities={activities}
          onChange={handleDataChange}
          onSubmit={loadHistoryData}
        />
        <FlightHistoryExport exportDisabled={isLoading} pointsData={pointsData.current} map={map} />
      </div>
    </div>
  );
};

export { FlightHistorySettings };
