import axios from 'axios';
import * as turf from '@turf/turf';
import { GeometryUtils } from './geometry.utils';
import { MapboxOverlay } from '@deck.gl/mapbox';
import { PathLayer } from 'deck.gl';

const heightColors = (field = 'alt') => [
  'interpolate',
  ['linear'],
  ['coalesce', ['get', field], 0], // Если "alt" равно null, использовать 0
  0,
  ['rgba', 0, 255, 0, 1], // 0 -> кислотно-зеленый
  2500,
  ['rgba', 100, 255, 255, 1], // 2500 -> ультра-яркий циан
  5000,
  ['rgba', 80, 230, 255, 1], // 5000 -> яркий циан
  7500,
  ['rgba', 40, 200, 255, 1], // 7500 -> электрик голубой
  10000,
  ['rgba', 0, 170, 255, 1], // 10000 -> неоновый голубой
  12500,
  ['rgba', 0, 140, 255, 1], // 12500 -> яркий голубой
  15000,
  ['rgba', 0, 130, 200, 1], // 15000 -> лазурный
  17500,
  ['rgba', 0, 100, 180, 1], // 17500 -> приглушенный голубой
  20000,
  ['rgba', 0, 80, 160, 1], // 20000 -> средний голубой
  50000,
  ['rgba', 0, 60, 140, 1], // 50000 -> темно-голубой
  75000,
  ['rgba', 0, 40, 120, 1], // 75000 -> глубокий голубой
  100000,
  ['rgba', 0, 20, 100, 1], // 100000 -> темно-синий
];
// const heightColors = (field = 'alt') => [
//   "interpolate",
//   ["linear"],
//   ["coalesce", ["get", field], 0],  // Если "alt" равно null, использовать 0
//   "rgba(0, 255, 0, 1)",            // 0 -> зелёный
//   2500, "rgba(102, 255, 102, 1)",  // 2500 -> светло-зелёный
//   5000, "rgba(255, 255, 0, 1)",    // 5000 -> жёлтый
//   7500, "rgba(255, 255, 102, 1)",  // 7500 -> светло-жёлтый
//   10000, "rgba(255, 127, 0, 1)",   // 10000 -> оранжевый
//   12500, "rgba(255, 191, 0, 1)",   // 12500 -> светло-оранжевый
//   15000, "rgba(255, 0, 0, 1)",     // 15000 -> красный
//   17500, "rgba(255, 102, 102, 1)", // 17500 -> светло-красный
//   20000, "rgba(178, 0, 0, 1)",     // 20000 -> тёмно-красный
//   50000, "rgba(0, 0, 255, 1)",     // 50000 -> синий
//   75000, "rgba(0, 0, 178, 1)",     // 75000 -> тёмно-синий
//   100000, "rgba(0, 0, 139, 1)"     // 100000 -> очень тёмно-синий
// ]

const get3DColor = (alt) => {
  if (alt < 2500) return [0, 255, 0]; // 0 -> зеленый
  if (alt < 5000) return [102, 255, 102]; // 2500 -> светло-зеленый
  if (alt < 7500) return [255, 255, 0]; // 5000 -> желтый
  if (alt < 10000) return [255, 255, 102]; // 7500 -> светло-желтый
  if (alt < 12500) return [255, 127, 0]; // 10000 -> оранжевый
  if (alt < 15000) return [255, 191, 0]; // 12500 -> светло-оранжевый
  if (alt < 17500) return [255, 0, 0]; // 15000 -> красный
  if (alt < 20000) return [255, 102, 102]; // 17500 -> светло-красный
  if (alt < 50000) return [178, 0, 0]; // 20000 -> темно-красный
  if (alt < 75000) return [0, 0, 255]; // 50000 -> синий
  if (alt < 100000) return [0, 0, 178]; // 75000 -> темно-синий

  return [0, 0, 139];
};
export class LayersUtils {
  constructor() {
    this.hoveredGeometryId = null;
  }

  static async toggleGoogleLayer({ map, source, mapType, layerTypes = [] }) {
    const getSessionKey = async () => {
      try {
        const { data } = await axios.post(
          `https://tile.googleapis.com/v1/createSession?key=${process.env.REACT_APP_GOOGLE_KEY}`,
          {
            mapType,
            layerTypes,
            language: 'en-US',
            region: 'US',
          }
        );

        return data.session;
      } catch (error) {
        console.error(error);
      }
    };

    const sessionKey = await getSessionKey();

    const urlWithKey = `https://tile.googleapis.com/v1/2dtiles/{z}/{x}/{y}?key=${process.env.REACT_APP_GOOGLE_KEY}&session=${sessionKey}`;

    map.addSource(source, {
      type: 'raster',
      tiles: [urlWithKey],
      tileSize: 256,
    });

    map.addLayer({
      id: `${source}-google`,
      source,
      type: 'raster',
    });
  }

  static addHoverLayer(map, source, layer) {
    const canvas = map.getCanvas();

    map.on('mousemove', layer, (e) => {
      if (e.features.length > 0) {
        if (this.hoveredGeometryId !== null) {
          map.setFeatureState({ source, id: this.hoveredGeometryId }, { hover: false });
        }
        canvas.style.cursor = 'pointer';
        this.hoveredGeometryId = e.features[0].id;
        map.setFeatureState({ source, id: this.hoveredGeometryId }, { hover: true });
      }
    });

    map.on('mouseleave', layer, () => {
      if (this.hoveredGeometryId !== null) {
        map.setFeatureState({ source, id: this.hoveredGeometryId }, { hover: false });
      }
      canvas.style.cursor = 'grab';
      this.hoveredGeometryId = null;
    });
  }

  static async toggle3DTerrain({ map, visible = true }) {
    if (!map) return;

    const SOURCE_ID = 'mapbox-dem';
    const SOURCE_LAYER_ID = 'hillshade-layer';

    const hillShadeIsActive = map.getSource(SOURCE_ID);

    if (hillShadeIsActive) {
      map.setLayoutProperty(SOURCE_LAYER_ID, 'visibility', visible ? 'visible' : 'none');

      if (visible) {
        map.setLayoutProperty(SOURCE_LAYER_ID, 'visibility', 'visible');
        map.setTerrain({ source: SOURCE_ID, exaggeration: 1.5 });
        map.moveLayer(SOURCE_LAYER_ID);
      } else {
        map.setLayoutProperty(SOURCE_LAYER_ID, 'visibility', 'none');
        map.setTerrain({ source: SOURCE_ID, exaggeration: 0 });
      }

      return;
    }

    map.addSource(SOURCE_ID, {
      type: 'raster-dem',
      // url: 'https://api.maptiler.com/tiles/terrain-rgb-v2/{z}/{x}/{y}.webp?key=QAhRFnpecjdzPphpL8hz',
      tiles: ['https://api.maptiler.com/tiles/terrain-rgb-v2/{z}/{x}/{y}.webp?key=QAhRFnpecjdzPphpL8hz'],
      tileSize: 512,
      maxzoom: 14,
    });

    map.setTerrain({ source: SOURCE_ID, exaggeration: 1.5 });

    map.addLayer({
      id: SOURCE_LAYER_ID,
      type: 'hillshade',
      source: SOURCE_ID,
      layout: {},
      paint: {
        'hillshade-highlight-color': '#ffffff',
        'hillshade-shadow-color': 'black',
        'hillshade-accent-color': '#aaaaaa',
      },
    });

    map.moveLayer(SOURCE_LAYER_ID);
  }

  static async addVectorLayer({ url, source, map }) {
    try {
      const { data } = await axios.get(url);

      Object.keys(data.sources).forEach((key) => {
        const sourceData = data.sources[key];

        if (sourceData.url) map.addSource(source, data.sources[key]);
      });

      data.layers.forEach((layer) => {
        const layerId = `${source}-${layer.id}`;

        if (layer.source) {
          map.addLayer({
            ...layer,
            id: layerId,
            source,
          });
        } else {
          map.addLayer({
            ...layer,
            id: layerId,
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  static addSelectedHistoryLayer(map, { points = null, view3D = false }) {
    if (!map) return;

    const LAYER_POINT_SOURCE = 'temporary-history-point';
    const LAYER_POINT_ID = 'temporary-history-point-layer';
    const LAYER_LINE_SOURCE = 'temporary-history-line';
    const LAYER_LINE_ID = 'temporary-history-line-layer';

    let existedLineSource = map.getSource(LAYER_LINE_SOURCE);
    let existedPointSource = map.getSource(LAYER_POINT_SOURCE);

    if ((!existedLineSource || !existedPointSource) && !points) return;

    if (!existedLineSource && !existedPointSource) {
      map.addSource(LAYER_LINE_SOURCE, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      map.addSource(LAYER_POINT_SOURCE, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      map.addLayer({
        id: LAYER_LINE_ID,
        type: 'line',
        source: LAYER_LINE_SOURCE,
        paint: {
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            5,
            1, // на малом зуме тонкая линия
            10,
            2, // средний зум
            15,
            4, // близкий зум
            20,
            6, // максимальное приближение
          ],
          'line-color': heightColors('elevation'),
        },
      });

      map.addLayer({
        id: LAYER_POINT_ID,
        type: 'circle',
        source: LAYER_POINT_SOURCE,
        paint: {
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            5,
            2, // маленькие точки на малом зуме
            10,
            4.5, // средний размер
            15,
            7, // большие точки при приближении
            20,
            10, // максимальный размер точек
          ],
          'circle-color': heightColors(),
        },
      });

      existedLineSource = map.getSource(LAYER_LINE_SOURCE);
      existedPointSource = map.getSource(LAYER_POINT_SOURCE);
    }

    const data = points ? GeometryUtils.generateSegmentsWithElevation(points) : existedLineSource?._data;

    existedLineSource.setData(data);

    existedPointSource.setData({
      type: 'FeatureCollection',
      features: points,
    });

    if (view3D) {
      const lines = GeometryUtils.geoJsonToPaths(data);

      // const allPoints = existedPointSource._data.features.map(feature => {
      //   const [lon, lat] = feature.geometry.coordinates
      //   const alt = feature.properties.alt || 0

      //   return {
      //     coordinates: [lon,lat,alt], // Точки в формате [долгота, широта, высота]
      //     color: get3DColor(alt),
      //     size: 0.5, // Радиус точки
      //   }
      // });

      const layers = [
        new PathLayer({
          id: 'selected-history-layer',
          data: lines,
          getPath: (d) => d.path,
          getColor: (d) => {
            const [, , alt] = d.path[1];

            return get3DColor(alt);
          },
          widthUnits: 'pixels',
          opacity: 1,
          getWidth: 12,
          rounded: true,
          widthScale: 1,
          pickable: true, // Делаем слой интерактивным
          autoHighlight: true,
          highlightColor: [255, 0, 0],
        }),
        // new PointCloudLayer({
        //   id: 'selected-history-point-layer',
        //   data: allPoints,
        //   getPosition: d => d.coordinates,
        //   getColor: d => d.color,
        //   getRadius: d => d.size,
        //   radiusUnits: 'meters',
        //   opacity: 1,
        //   pickable: true,
        // }),
      ];

      map.setLayoutProperty(LAYER_POINT_ID, 'visibility', 'none');
      map.setLayoutProperty(LAYER_LINE_ID, 'visibility', 'none');

      if (map?.selectedHistory3D) {
        map.selectedHistory3D.setProps({ layers });
        return;
      }

      const deckOverlay = new MapboxOverlay({ layers });
      map.addControl(deckOverlay);
      map.selectedHistory3D = deckOverlay;
      return;
    }

    if (map?.selectedHistory3D) map.selectedHistory3D.setProps({ layers: [] });

    map.setLayoutProperty(LAYER_POINT_ID, 'visibility', 'visible');
    map.setLayoutProperty(LAYER_LINE_ID, 'visibility', 'visible');
    map.moveLayer(LAYER_LINE_ID);
    map.moveLayer(LAYER_POINT_ID);
  }

  static addMarkerLayer(map, { center, radius, sourceName, color = 'red' }) {
    const source = `${sourceName}-marker`;
    const fillLayer = `${source}-fill`;
    const lineLayer = `${source}-line`;

    if (center === 'clear') {
      map.removeLayer(fillLayer);
      map.removeLayer(lineLayer);
      map.removeSource(source);

      return;
    }

    const markerSource = map.getSource(source);

    const data = {
      type: 'FeatureCollection',
      features: [turf.buffer(turf.point(center), radius, { units: 'meters' })],
    };

    if (markerSource) {
      markerSource.setData(data);
      return;
    }

    map.addSource(source, {
      type: 'geojson',
      data,
    });

    map.addLayer({
      id: fillLayer,
      type: 'fill-extrusion',
      source: source,
      layout: {},
      paint: {
        'fill-extrusion-color': color,
        'fill-extrusion-height': 999999999,
        'fill-extrusion-base': 0,
        'fill-extrusion-opacity': 0.4,
      },
      minzoom: 9,
    });

    map.addLayer({
      id: lineLayer,
      type: 'line',
      source: source,
      layout: {},
      paint: {
        'line-color': color,
        'line-width': 3,
      },
      minzoom: 9,
    });
  }

  static addRadiusPoint(map, { center, radius, sourceName, color = 'red', visibility = 'visible' }) {
    const source = `${sourceName}-marker`;
    const lineLayer = `${source}-line`;

    if (center === 'clear') {
      map.removeLayer(lineLayer);
      map.removeSource(source);

      return;
    }

    const radiusSource = map.getSource(source);

    const data = {
      type: 'FeatureCollection',
      features: [turf.buffer(turf.point(center), radius, { units: 'nauticalmiles' })],
    };

    if (radiusSource) {
      radiusSource.setData(data);
      return;
    } else {
      map.addSource(source, {
        type: 'geojson',
        data,
      });

      map.addLayer({
        id: lineLayer,
        type: 'line',
        source: source,
        layout: {},
        paint: {
          'line-color': color,
          'line-width': 5,
          'line-dasharray': [0, 1, 3],
        },
      });
    }

    if (visibility) {
      map.setLayoutProperty(lineLayer, 'visibility', visibility);
    }

    map.moveLayer(lineLayer);
  }
}
