import React, { useEffect, useRef, useState } from 'react';
import AccordionTab from 'components/base/AccordionTab';
import DateTimePicker from 'components/UI/DatePicker';

const generateCurrentDate = () => {
  const currentDate = new Date();
  const pastHour = new Date(currentDate.getTime() - 60 * 60 * 1000);

  return {
    from: pastHour.toISOString(),
    to: currentDate.toISOString(),
  };
};

const defaultValue = {
  radius: 30,
  ...generateCurrentDate(),
};

const FlightHistoryData = ({ onChange }) => {
  const [isTabOpened, setTabOpened] = useState(true);

  const [historyData, setHistoryData] = useState(defaultValue);

  const handleHistoryDataChange = (data) => setHistoryData({ ...historyData, ...data });

  const onTabToggle = (value) => setTabOpened(value);

  const handleDateSelect = (date) => handleHistoryDataChange(date);
  useEffect(() => onChange(historyData), [historyData]);

  return (
    <div className="flight-history-tab">
      <AccordionTab label="Time" name="date" opened={isTabOpened} setOpened={onTabToggle}>
        <DateTimePicker
          onChange={handleDateSelect}
          defaultValue={{
            from: '2025-02-07T10:30',
            to: '2025-02-07T12:45',
          }}
        />
      </AccordionTab>
    </div>
  );
};

export { FlightHistoryData };
