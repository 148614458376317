import * as tokml from 'tokml';
import JSZip from 'jszip';
import * as turf from '@turf/turf';

export class GeometryUtils {
  static geoJsonToPaths(geoJson) {
    if (geoJson.type !== 'FeatureCollection') {
      throw new Error('GeoJSON должен быть FeatureCollection');
    }

    return geoJson.features
      .filter((feature) => feature.geometry.type === 'LineString')
      .map((feature) => ({
        id: feature.id,
        path: feature.geometry.coordinates.map((coord) => {
          const [longitude, latitude, elevation = 0] = coord;

          return [longitude, latitude, elevation < 0 ? 0 : elevation];
        }),
      }));
  }

  static generateSegmentsWithElevation(points) {
    const features = [];

    for (let i = 0; i < points.length - 1; i++) {
      const start = points[i].geometry.coordinates;
      const end = points[i + 1].geometry.coordinates;

      const elevation = points[i].properties.alt;

      features.push({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [start[0], start[1], start[2]],
            [end[0], end[1], end[2]],
          ],
        },
        properties: {
          elevation: elevation,
        },
      });
    }

    return {
      type: 'FeatureCollection',
      features: features,
    };
  }

  static convertGeojsonToKML(geojson, altitudeMode = 'absolute') {
    try {
      let kmlData = tokml(geojson);

      kmlData = kmlData.replace(
        /<LineString>/g,
        `<styleUrl>#polygonStyle</styleUrl><LineString>\n  <extrude>1</extrude> <altitudeMode>${altitudeMode}</altitudeMode>`
      );

      kmlData = kmlData.replace(/<Placemark>/g, `<Placemark>\n  <styleUrl>#msn_shaded_dot</styleUrl>\n`);

      kmlData = kmlData.replace(
        /<Point>/g,
        `<Point>\n  <altitudeMode>${altitudeMode}</altitudeMode><extrude>1</extrude>`
      );

      return kmlData;
    } catch (err) {
      alert('Неверный формат GeoJSON: ' + err.message);
    }
  }

  static downloadKML(geojson, fileName = 'kml-export') {
    const kml = this.convertGeojsonToKML(geojson);
    const kmlLink = document.createElement('a');

    const kmlBlob = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });

    kmlLink.download = `${fileName}.kml`;

    kmlLink.href = URL.createObjectURL(kmlBlob);

    document.body.appendChild(kmlLink);

    kmlLink.click();

    kmlLink.remove();
  }

  static extractPlacemarkContent(kml) {
    const documentStart = kml.indexOf('<Document>');
    const documentEnd = kml.indexOf('</Document>');

    if (documentStart !== -1 && documentEnd !== -1) {
      return kml.substring(documentStart + '<Document>'.length, documentEnd);
    }

    return null;
  }

  static downloadKMZ(geojson, additionalData, fileName = 'kmz-export') {
    const layers = {};

    geojson.features.forEach((feature) => {
      const { callsign } = feature.properties;

      if (!layers[callsign]) {
        layers[callsign] = [feature];
        return;
      }

      layers[callsign].push(feature);
    });

    const zip = new JSZip();

    let kml = '';
    let kmlWaypoints = '';

    Object.entries(layers).forEach(([callsign, features]) => {
      const geojsonData = turf.featureCollection(features);

      kml += `
      <Folder>
        <name>${callsign}</name>
        ${this.extractPlacemarkContent(this.convertGeojsonToKML(geojsonData))}
      </Folder>
      `;
    });

    if (additionalData && additionalData.length) {
      const pointsLayers = {};

      additionalData[0].features.forEach((feature) => {
        const { callsign } = feature.properties;

        if (!pointsLayers[callsign]) {
          pointsLayers[callsign] = [feature];
          return;
        }

        pointsLayers[callsign].push(feature);
      });

      Object.entries(pointsLayers).forEach(([callsign, features]) => {
        const geojsonData = turf.featureCollection(features);

        kmlWaypoints += `
        <Folder>
          <name>${callsign}</name>
          ${this.extractPlacemarkContent(this.convertGeojsonToKML(geojsonData))}
        </Folder>
        `;
      });
    }

    // let kml = `
    // <Style id="polygonStyle">
    //   <PolyStyle>
    //     <!-- Полупрозрачный желтый для полигона -->
    //     <color>7d00ffff</color> <!-- Полупрозрачный желтый для полигона -->
    //   </PolyStyle>
    //   <LineStyle>
    //     <!-- Более прозрачный желтый для линии -->
    //     <color>7d00ffff</color> <!-- Более прозрачный желтый для линии -->
    //     <width>3</width> <!-- Толщина линии -->
    //   </LineStyle>
    // </Style>
    //   <Folder>
    //     <name>Layer 0</name>
    //     ${this.extractPlacemarkContent(this.convertGeojsonToKML(geojson))}
    //   </Folder>
    // `

    // if (additionalData && additionalData.length) additionalData.forEach((data, index) => {
    //   kml += `
    //   <Folder>
    //     <name>Layer ${index + 1}</name>
    //     ${this.extractPlacemarkContent(this.convertGeojsonToKML(data))}
    //   </Folder>
    //   `
    // })

    zip.file(
      'doc.kml',
      `<?xml version="1.0" encoding="UTF-8"?>
      <kml xmlns="http://www.opengis.net/kml/2.2">
        <Document>
          <name>Flight history</name>

          <Style id="msn_shaded_dot">
            <IconStyle>
                <color>ffe8ac3b</color> <!-- Оранжевый цвет -->
                <scale>0.6</scale>
                <Icon>
                    <href>http://maps.google.com/mapfiles/kml/shapes/shaded_dot.png</href>
                </Icon>
            </IconStyle>
            <BalloonStyle>
              <text><![CDATA[
                <div style="font-family:Arial; font-size: 14px; background-color: #cce9ff; padding: 4px 0 0 0; padding-top: 0px; border-radius:8px; display:flex; flex-direction:column; border: 1px solid black;">
                  <h3 style="display: block; margin-bottom: 4px; margin-top: 4px; color: black; text-align: center;"><b>$[registration]</b></h3>
                  <div style="display: flex; justify-content: center; align-items: center; width: 100%; text-align: center; margin-bottom: 4px;">
                    <b style="color:black; margin: 0 4px;">⏲ $[speed]</b>
                    <b style="color:black; margin: 0 4px;">↕️ $[altitude]</b>
                    <b style="color:black; margin: 0 4px;">〰 $[vertical_rate]</b>
                  </div>
                  <div style="background: #59afff; color: black; padding: 4px; margin: 0; text-align: center; display: flex; flex-direction: column; align-items: center;">
                    <div #inline style="display: flex; justify-content: center; align-items: center; gap: 10px; text-align: center; flex-wrap: nowrap; margin-bottom: 2px;">
                      <span>$[callsign]</span>
                      <span>A21N</span>
                    </div>

                    <div style="text-align: center; margin-bottom: 2px;">
                      <span>SQ $[squawk]</span>
                      </br style="margin-bottom: 2px;">
                      <span>$[source]</span>
                    </div>
                    <i>$[received_at]</i>
                  </div>
                </div>
              ]]></text>
            </BalloonStyle>
          </Style>

          <Style id="polygonStyle">
            <PolyStyle>
              <color>66FFFFFF</color>
              <outline>0</outline>
            </PolyStyle>
            <LineStyle>
              <color>FFFFFFFF</color>
              <width>3</width>
            </LineStyle>
          </Style>
          <Folder>
            <name>Routes</name>
            ${kml}
          </Folder>
          ${
            additionalData?.length &&
            `
            <Folder>
              <name>Waypoints</name>
              ${kmlWaypoints}
              <visibility>0</visibility>
          </Folder>`
          }
        </Document>
      </kml>
    `
    );

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = `${fileName}.kmz`;
      link.click();
    });
  }
}
