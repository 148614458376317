import React, { useEffect, useState } from 'react';
import './DatePicker.css';

const DateTimePicker = ({ onChange, defaultValue }) => {
  const [fromDate, setFromDate] = useState('');
  const [fromTime, setFromTime] = useState('');
  const [toDate, setToDate] = useState('');
  const [toTime, setToTime] = useState('');

  const formatDefaultTime = () => {
    const { from, to } = defaultValue;

    const [dateFrom, timeFrom] = from.split('T');
    const [dateTo, timeTo] = to.split('T');

    setFromDate(dateFrom);
    setFromTime(timeFrom);

    setToDate(dateTo);
    setToTime(timeTo);
  };

  useEffect(() => {
    formatDefaultTime();
  }, []);

  useEffect(() => {
    const from = fromDate && fromTime ? new Date(`${fromDate}T${fromTime}`).toISOString() : null;
    const to = toDate && toTime ? new Date(`${toDate}T${toTime}`).toISOString() : null;

    if (onChange) {
      onChange({ from, to });
    }
  }, [fromDate, fromTime, toDate, toTime]);

  return (
    <div className="vt-datepicker">
      <div className="vt-datepicker__wrapper">
        <div className="vt-datepicker__block">
          <label>Date (from)</label>
          <input
            type="date"
            value={fromDate}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
          />
        </div>
        <div className="vt-datepicker__block">
          <label>Date (to)</label>
          <input
            type="date"
            value={toDate}
            onChange={(e) => {
              setToDate(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="vt-datepicker__wrapper">
        <div className="vt-datepicker__block">
          <label>Time (from)</label>
          <input
            type="time"
            value={fromTime}
            onChange={(e) => {
              setFromTime(e.target.value);
            }}
          />
        </div>
        <div className="vt-datepicker__block">
          <label>Time (to)</label>
          <input
            type="time"
            value={toTime}
            onChange={(e) => {
              setToTime(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DateTimePicker;
