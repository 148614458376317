export const ScreenType = {
  IFRAME: 'iframe',
  SCREEN: 'screen',
};

export const ScreenTypeToString = {
  [ScreenType.IFRAME]: 'iFrame',
  [ScreenType.SCREEN]: 'Screen',
};

export const fidsTypeOptions = [
  { name: 'iFrame', value: ScreenType.IFRAME },
  { name: 'Screen', value: ScreenType.SCREEN },
];

export const DATA_SOURCE_IDS = {
  DEPARTURES: 1,
  ARRIVALS: 2,
  AIRCRAFTS: 3,
  AIRCRAFT_OPERATIONS: 4,
};

export const DataSourceTypeToString = {
  [DATA_SOURCE_IDS.DEPARTURES]: 'Departures',
  [DATA_SOURCE_IDS.ARRIVALS]: 'Arrivals',
  [DATA_SOURCE_IDS.AIRCRAFTS]: 'Map',
  [DATA_SOURCE_IDS.AIRCRAFT_OPERATIONS]: 'Map & Ops',
};

export const dataSourceOptions = [
  { name: DataSourceTypeToString[DATA_SOURCE_IDS.DEPARTURES], value: DATA_SOURCE_IDS.DEPARTURES },
  { name: DataSourceTypeToString[DATA_SOURCE_IDS.ARRIVALS], value: DATA_SOURCE_IDS.ARRIVALS },
  { name: DataSourceTypeToString[DATA_SOURCE_IDS.AIRCRAFTS], value: DATA_SOURCE_IDS.AIRCRAFTS },
  { name: DataSourceTypeToString[DATA_SOURCE_IDS.AIRCRAFT_OPERATIONS], value: DATA_SOURCE_IDS.AIRCRAFT_OPERATIONS },
];
