import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { createScreen, updateScreen } from 'redux/modules/screens';
import Button from 'components/base/Button';
import FormField from 'components/base/FormField';
import FormGroup from 'components/base/FormGroup';
import CustomInput from 'components/base/CustomInput';
import FormikInput from 'components/base/CustomInput/FormikInput';
import FormikSelect from 'components/base/CustomSelect/FormikSelect';
import { handleToasts } from 'utils/handleThunkRequest';
import { getObjectDifferences } from 'utils/formFieldsHelpers';
import { ScreenType, fidsTypeOptions, dataSourceOptions, DATA_SOURCE_IDS } from '../screenUtils';

const defaultInitialValues = {
  type: ScreenType.SCREEN,
  dataSourceId: DATA_SOURCE_IDS.DEPARTURES,
  name: '',
  code: '',
};

// TODO: REFACTOR write a hook and reuse it in index.jsx to rename title of the form
const ScreensForm = ({ screen, isNew }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isShareForm = searchParams.get('share') === 'true';

  const initialValues = screen || defaultInitialValues;

  const screenFormValidation = (values) => {
    const errors = {};

    if (isNew && values.type === ScreenType.SCREEN && !values.code) {
      errors.code = 'Required';
    }

    return errors;
  };

  const formOnSubmit = (values, { setSubmitting }) => {
    if (isNew) {
      const convertedValues = { ...values, code: values.code ? Number(values.code) : undefined };
      dispatch(createScreen(convertedValues)).then(
        handleToasts({
          successMessage: 'FIDS successfully created',
          successCallback: (data) => {
            setSubmitting(false);
            history.push(`/fids/${data.payload.id}`);
          },
          errorCallback: () => {
            setSubmitting(false);
          },
        })
      );
    } else {
      const id = values.id;
      const entityUpdates = getObjectDifferences(initialValues, values);
      delete entityUpdates.code;
      dispatch(updateScreen({ id, ...entityUpdates })).then(
        handleToasts({
          successMessage: 'FIDS successfully updated',
          successCallback: () => {
            setSubmitting(false);
          },
          errorCallback: () => {
            setSubmitting(false);
          },
        })
      );
    }
  };

  const renderShareForm = (setValues) => {
    const iframeCode = `<iframe src="${
      process.env.REACT_APP_FIDS_URL || 'https://fids.virtower.net'
    }?theme=light&iframe=true&token=${
      screen?.token
    }" width="100%" height="650" style="border:none;"frameborder="0" allowfullscreen referrerpolicy="strict-origin-when-cross-origin"></iframe>`;
    return (
      <>
        <FormField label="Embed This iFrame" name="iframe">
          <CustomInput textarea minHeight={230} value={iframeCode} readOnly />
          <p className="form__reminder form__reminder_input">
            To add this content to your website, simply copy the code below and paste it into your HTML where you’d like
            it to appear.
          </p>
        </FormField>{' '}
        <Button
          className="form-group-column__button"
          mods={{ action: true }}
          type="button"
          onClick={() => {
            navigator.clipboard.writeText(iframeCode);
          }}
        >
          Copy
        </Button>
      </>
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={screenFormValidation}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={formOnSubmit}
    >
      {({ isSubmitting, values, setValues }) => (
        <Form>
          <FormGroup.Column>
            {isShareForm ? (
              renderShareForm(setValues)
            ) : (
              <>
                <FormField label="Type" name="type">
                  <FormikSelect name="type" select options={fidsTypeOptions} required disabled={!isNew} />
                </FormField>

                <FormField label="Name" name="name">
                  <FormikInput name="name" placeholder="" required />
                </FormField>

                {values?.type === ScreenType.SCREEN && (
                  <FormField label="Data Source" name="dataSourceId">
                    <FormikSelect name="dataSourceId" select options={dataSourceOptions} required />
                  </FormField>
                )}

                {isNew && values?.type === ScreenType.SCREEN && (
                  <FormField label="Code" name="code">
                    <FormikInput
                      name="code"
                      placeholder="123456"
                      pattern="^[0-9]+$"
                      title="6-digit code"
                      maxLength={6}
                      minLength={6}
                      required
                      readOnly={!isNew}
                    />
                  </FormField>
                )}

                <Button
                  className="form-group-column__button"
                  mods={{ action: true, loading: isSubmitting }}
                  disabled={isSubmitting}
                  type="submit"
                >
                  {screen ? 'Update' : 'Save'}
                </Button>
              </>
            )}
          </FormGroup.Column>
        </Form>
      )}
    </Formik>
  );
};

ScreensForm.propTypes = {
  screen: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    token: PropTypes.string,
    updatedAt: PropTypes.string,
    dataSourceId: PropTypes.number,
    airport: PropTypes.shape({
      icao: PropTypes.string,
    }),
  }),
  isNew: PropTypes.bool,
};

export default ScreensForm;
