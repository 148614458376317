import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMapType,
  setWeatherRadarType,
  setIconType,
  toggleLayer,
  toggleSource,
  toggleOrigin,
  setTagType,
} from 'redux/modules/map';
import { toggleModalAccordion } from 'redux/modules/modal';
import { MAP_CONTROLS, ACCORDION_CONTROLS } from 'utils/globalConstants';
import { permissions } from 'utils/userPermissions';
import DNDModal from 'components/base/DNDModal';
import Checkbox from 'components/base/Checkbox';
import FormField from 'components/base/FormField';
import AccordionTab from 'components/base/AccordionTab';
import {
  baseLayers,
  baseLayersLabels,
  mapIcons,
  mapIconsLabels,
  tagTypes,
  tagTypeToLabel,
  weatherRadarLayerDetailsList,
} from 'components/common/LeafletMap';
import api from 'api/api';

import './MapLayersModal.css';
import { View3DControl } from '../3DView/View3D';
import { Terrain3DControl } from '../Terrain3D/Terrain3D';

const MapLayersModal = ({ top = 0, left = 0, width = 300, height }) => {
  const dispatch = useDispatch();
  const { mapType, mapWeatherRadarType, iconType, sources, attributes, tagType, isBasicMap, origins } = useSelector(
    (state) => state.map
  );
  const { permissionsList, isDebugMode } = useSelector((state) => state.auth.userInfo);
  const { accordions, isNoiseAbatementPage } = useSelector((state) => state.modal);
  const { sensorsLayer, turnedOffLayer, geofenceLayer, airspacesLayer, airportsLayer, dimmingLayer } = attributes;
  const isGeofenceLayerAvailable = permissionsList.includes(permissions.OPTION_GEOFENCE_LAYER);
  const isOriginFiltrationAvailable = permissionsList.includes(permissions.OPTION_ORIGIN_FILTER);

  const handleMapTypeChange = (e) => {
    const newMapType = e.currentTarget.value;
    dispatch(setMapType(newMapType));
    api
      .saveAuditLog({
        endpoint: 'Map Type',
        body: { type: newMapType },
      })
      .catch((error) => {
        console.error('Failed to save audit log for map type:', error);
      });
  };

  const handleLayerToggle = (layerName) => {
    dispatch(toggleLayer(layerName));
    api
      .saveAuditLog({
        endpoint: 'Layer Toggle',
        body: { attributes, layer: layerName, stateChange: !attributes[layerName] },
      })
      .catch((error) => {
        console.error('Failed to save audit log for map layer:', error);
      });
  };

  const handleWeatherRadarChange = (type) => {
    dispatch(setWeatherRadarType(type));
    api
      .saveAuditLog({
        endpoint: 'Weather Radar',
        body: { type },
      })
      .catch((error) => {
        console.error('Failed to save audit log for weather radar:', error);
      });
  };

  const renderAllItems = () => {
    return (
      <>
        <div className="dndmodal__divider" />
        {isDebugMode && <View3DControl />}
        {isDebugMode && (
          <>
            <div className="dndmodal__divider" />
            <Terrain3DControl />
          </>
        )}
        <div className="dndmodal__divider" />
        <AccordionTab
          label="Map Type"
          name={ACCORDION_CONTROLS.LAYERS_MAP}
          opened={accordions[ACCORDION_CONTROLS.LAYERS_MAP]}
          setOpened={(checked) =>
            dispatch(toggleModalAccordion({ name: ACCORDION_CONTROLS.LAYERS_MAP, state: checked }))
          }
        >
          {baseLayers.map((layer, i) => {
            return (
              <FormField
                mods={{ row: true, largeLabel: true }}
                label={baseLayersLabels[layer]}
                name={layer}
                key={layer}
                className="map-base-layer-field"
                classNameLabel={`ga_map_layers_mapLayer_${layer}`}
              >
                <Checkbox
                  label={layer}
                  type="radio"
                  name="map-layer"
                  id={layer}
                  value={layer}
                  checked={mapType === layer}
                  onChange={handleMapTypeChange}
                  classNameInput={`ga_map_layers_mapLayer_${layer}`}
                />
              </FormField>
            );
          })}
        </AccordionTab>
        <div className="dndmodal__divider" />

        <AccordionTab
          label="Map Layers"
          name={ACCORDION_CONTROLS.LAYERS_LAYER}
          opened={accordions[ACCORDION_CONTROLS.LAYERS_LAYER]}
          setOpened={(checked) =>
            dispatch(toggleModalAccordion({ name: ACCORDION_CONTROLS.LAYERS_LAYER, state: checked }))
          }
        >
          <FormField
            mods={{ row: true, largeLabel: true }}
            label="Airports"
            name="airportsLayer"
            classNameLabel="ga_map_layers_airportsLayer"
          >
            <Checkbox
              classNameInput="ga_map_layers_airportsLayer"
              type="checkbox"
              name="airportsLayer"
              id="airportsLayer"
              value="airportsLayer"
              checked={Boolean(airportsLayer)}
              onChange={() => handleLayerToggle('airportsLayer')}
              disabled={airportsLayer === null}
            />
          </FormField>
          <FormField
            mods={{ row: true, largeLabel: true }}
            label="Airspaces"
            name="airspacesLayer"
            classNameLabel="ga_map_layers_airspacesLayer"
          >
            <Checkbox
              classNameInput="ga_map_layers_airspacesLayer"
              type="checkbox"
              name="airspacesLayer"
              id="airspacesLayer"
              value="airspacesLayer"
              checked={Boolean(airspacesLayer)}
              onChange={() => handleLayerToggle('airspacesLayer')}
              disabled={airspacesLayer === null}
            />
          </FormField>
          {!isNoiseAbatementPage && (
            <>
              <FormField
                mods={{ row: true, largeLabel: true }}
                label="Sensors"
                name="sensorsLayer"
                classNameLabel="ga_map_layers_sensorsLayer"
              >
                <Checkbox
                  classNameInput="ga_map_layers_sensorsLayer"
                  type="checkbox"
                  name="sensorsLayer"
                  id="sensorsLayer"
                  value="sensorsLayer"
                  checked={sensorsLayer}
                  onChange={() => handleLayerToggle('sensorsLayer')}
                />
              </FormField>

              <FormField
                mods={{ row: true, largeLabel: true }}
                label="Offline Aircraft/Vehicle"
                name="turnedOffLayer"
                classNameLabel="ga_map_layers_turnedOffLayer"
              >
                <Checkbox
                  classNameInput="ga_map_layers_turnedOffLayer"
                  type="checkbox"
                  name="turnedOffLayer"
                  id="turnedOffLayer"
                  value="turnedOffLayer"
                  checked={turnedOffLayer}
                  onChange={() => handleLayerToggle('turnedOffLayer')}
                />
              </FormField>
              {isGeofenceLayerAvailable && (
                <FormField
                  mods={{ row: true, largeLabel: true }}
                  label="Geofences"
                  name="geofenceLayer"
                  classNameLabel="ga_map_layers_geofenceLayer"
                >
                  <Checkbox
                    classNameInput="ga_map_layers_geofenceLayer"
                    type="checkbox"
                    name="geofenceLayer"
                    id="geofenceLayer"
                    value="geofenceLayer"
                    checked={geofenceLayer}
                    onChange={() => handleLayerToggle('geofenceLayer')}
                  />
                </FormField>
              )}
              <FormField
                mods={{ row: true, largeLabel: true }}
                label="Dimming"
                name="dimmingLayer"
                classNameLabel="ga_map_layers_dimmingLayer"
              >
                <Checkbox
                  classNameInput="ga_map_layers_dimmingLayer"
                  type="checkbox"
                  name="dimmingLayer"
                  id="dimmingLayer"
                  value="dimmingLayer"
                  checked={dimmingLayer}
                  onChange={() => handleLayerToggle('dimmingLayer')}
                />
              </FormField>
            </>
          )}
        </AccordionTab>
        {!isNoiseAbatementPage && (
          <>
            <div className="dndmodal__divider" />
            <AccordionTab
              label="NOAA Weather Radar"
              name={ACCORDION_CONTROLS.LAYERS_WEATHER}
              opened={accordions[ACCORDION_CONTROLS.LAYERS_WEATHER]}
              setOpened={(checked) =>
                dispatch(toggleModalAccordion({ name: ACCORDION_CONTROLS.LAYERS_WEATHER, state: checked }))
              }
            >
              {weatherRadarLayerDetailsList.map((weatherRadarDetail) => {
                const { type, label, title } = weatherRadarDetail;
                return (
                  <FormField
                    mods={{ row: true, largeLabel: true }}
                    label={label}
                    title={title}
                    name={type}
                    key={type}
                    classNameLabel={`ga_map_layers_weatherLayerType_${type}`}
                  >
                    <Checkbox
                      type="checkbox"
                      name="map-weatherLayerType"
                      id={type}
                      value={type}
                      checked={type === mapWeatherRadarType}
                      onClick={() => handleWeatherRadarChange(type)}
                      onChange={() => {}}
                      classNameInput={`ga_map_layers_weatherLayerType_${type}`}
                    />
                  </FormField>
                );
              })}
            </AccordionTab>
            <div className="dndmodal__divider" />

            <AccordionTab
              label="Aircraft/Vehicle Icons"
              name={ACCORDION_CONTROLS.LAYERS_TARGET}
              opened={accordions[ACCORDION_CONTROLS.LAYERS_TARGET]}
              setOpened={(checked) =>
                dispatch(toggleModalAccordion({ name: ACCORDION_CONTROLS.LAYERS_TARGET, state: checked }))
              }
            >
              {mapIcons.map((iconsType) => {
                return (
                  <FormField
                    mods={{ row: true, largeLabel: true }}
                    label={mapIconsLabels[iconsType]}
                    name={iconsType}
                    key={iconsType}
                    classNameLabel={clsx(`ga_map_layers_map_iconsType_${iconsType}`)}
                  >
                    <Checkbox
                      label={iconsType}
                      type="radio"
                      name="map-iconsType"
                      id={iconsType}
                      value={iconsType}
                      checked={iconType === iconsType}
                      onChange={(e) => dispatch(setIconType(e.currentTarget.value))}
                      classNameInput={`ga_map_layers_map_iconsType_${iconsType}`}
                    />
                  </FormField>
                );
              })}
            </AccordionTab>
            <div className="dndmodal__divider" />

            <AccordionTab
              label="Tags"
              name={ACCORDION_CONTROLS.LAYERS_TAGS}
              opened={accordions[ACCORDION_CONTROLS.LAYERS_TAGS]}
              setOpened={(checked) =>
                dispatch(toggleModalAccordion({ name: ACCORDION_CONTROLS.LAYERS_TAGS, state: checked }))
              }
            >
              {tagTypes.map((tagsType) => {
                return (
                  <FormField
                    mods={{ row: true, largeLabel: true }}
                    label={tagTypeToLabel[tagsType]}
                    name={tagsType}
                    key={tagsType}
                    classNameLabel={`ga_map_layers_map_tagsType_${tagsType}`}
                  >
                    <Checkbox
                      label={tagsType}
                      type="radio"
                      name="map-tagsType"
                      id={tagsType}
                      value={tagsType}
                      checked={tagType === tagsType}
                      onChange={(e) => dispatch(setTagType(e.currentTarget.value))}
                      classNameInput={`ga_map_layers_map_tagsType_${tagsType}`}
                    />
                  </FormField>
                );
              })}
            </AccordionTab>
            <div className="dndmodal__divider" />

            <AccordionTab
              label="Sources"
              name={ACCORDION_CONTROLS.LAYERS_SOURCE}
              opened={accordions[ACCORDION_CONTROLS.LAYERS_SOURCE]}
              setOpened={(checked) =>
                dispatch(toggleModalAccordion({ name: ACCORDION_CONTROLS.LAYERS_SOURCE, state: checked }))
              }
            >
              <FormField
                mods={{ row: true, largeLabel: true }}
                label="ADS-B"
                name="sourceA"
                classNameLabel="ga_map_layers_map_source_sourceA"
              >
                <Checkbox
                  classNameInput="ga_map_layers_map_source_sourceA"
                  type="checkbox"
                  name="sourceA"
                  id="sourceA"
                  value="sourceA"
                  checked={sources.a}
                  onChange={() => dispatch(toggleSource('a'))}
                />
              </FormField>
              <FormField
                mods={{ row: true, largeLabel: true }}
                label="UAT"
                name="sourceU"
                classNameLabel="ga_map_layers_map_source_sourceU_A"
              >
                <Checkbox
                  classNameInput="ga_map_layers_map_source_sourceU_A"
                  type="checkbox"
                  name="sourceU"
                  id="sourceU"
                  value="sourceU"
                  checked={sources.u}
                  onChange={() => dispatch(toggleSource('u'))}
                />
              </FormField>
              <FormField
                mods={{ row: true, largeLabel: true }}
                label="MLAT Transponder"
                name="sourceM"
                classNameLabel="ga_map_layers_map_source_sourceM"
              >
                <Checkbox
                  classNameInput="ga_map_layers_map_source_sourceM"
                  type="checkbox"
                  name="sourceM"
                  id="sourceM"
                  value="sourceM"
                  checked={sources.m}
                  onChange={() => {
                    dispatch(toggleSource('m'));
                    dispatch(toggleSource('c'));
                  }}
                />
              </FormField>
              <FormField
                mods={{ row: true, largeLabel: true }}
                label="TIS-B"
                name="sourceT"
                classNameLabel="ga_map_layers_map_source_sourceT"
              >
                <Checkbox
                  classNameInput="ga_map_layers_map_source_sourceT"
                  type="checkbox"
                  name="sourceT"
                  id="sourceT"
                  value="sourceT"
                  checked={sources.t}
                  onChange={() => dispatch(toggleSource('t'))}
                  disabled
                />
              </FormField>
              <FormField
                mods={{ row: true, largeLabel: true }}
                label="Flarm"
                name="sourceF"
                classNameLabel="ga_map_layers_map_source_sourceF"
              >
                <Checkbox
                  classNameInput="ga_map_layers_map_source_sourceF"
                  type="checkbox"
                  name="sourceF"
                  id="sourceF"
                  value="sourceF"
                  checked={sources.f}
                  onChange={() => dispatch(toggleSource('f'))}
                  disabled
                />
              </FormField>
              {isOriginFiltrationAvailable && (
                <>
                  <div className="dndmodal__divider" />
                  <FormField
                    mods={{ row: true, largeLabel: true }}
                    label="UAX Origin"
                    name="originUAX"
                    title="Data coming from our UAX MLAT Server. Includes decoded uax and uax sensors."
                  >
                    <Checkbox
                      type="checkbox"
                      name="originUAX"
                      id="originUAX"
                      value="originUAX"
                      checked={origins.UAX}
                      onChange={() => dispatch(toggleOrigin('UAX'))}
                    />
                  </FormField>
                  <FormField
                    mods={{ row: true, largeLabel: true }}
                    label="VT Origin"
                    name="originVT"
                    title="Data coming from our VT MLAT Server. Includes decoded uax and vt sensors."
                  >
                    <Checkbox
                      type="checkbox"
                      name="originVT"
                      id="originVT"
                      value="originVT"
                      checked={origins.VT}
                      onChange={() => dispatch(toggleOrigin('VT'))}
                    />
                  </FormField>
                </>
              )}
            </AccordionTab>
          </>
        )}
      </>
    );
  };

  const renderBasicItems = () => {
    return (
      <>
        {baseLayers.map((layer) => {
          return (
            <FormField
              mods={{ row: true, largeLabel: true }}
              label={baseLayersLabels[layer]}
              name={layer}
              key={layer}
              className="map-base-layer-field"
            >
              <Checkbox
                label={layer}
                type="radio"
                name="map-layer"
                id={layer}
                value={layer}
                checked={mapType === layer}
                onChange={(e) => dispatch(setMapType(e.currentTarget.value))}
              />
            </FormField>
          );
        })}
        <FormField mods={{ row: true, largeLabel: true }} label="Dimming layer" name="dimmingLayer">
          <Checkbox
            type="checkbox"
            name="dimmingLayer"
            id="dimmingLayer"
            value="dimmingLayer"
            checked={dimmingLayer}
            onChange={() => dispatch(toggleLayer('dimmingLayer'))}
          />
        </FormField>
      </>
    );
  };

  return (
    <DNDModal
      id={MAP_CONTROLS.MAP_LAYERS}
      left={left}
      top={top}
      title="Charts, Layers & Sources"
      width={width}
      height={height}
    >
      <div className="map-layers">{isBasicMap ? renderBasicItems() : renderAllItems()}</div>
    </DNDModal>
  );
};

MapLayersModal.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MapLayersModal;
